<template>
  <div id="details">
    <div class="row">
      <div>Ban Date</div>
      <div>{{ options.date }}</div>
    </div>
    <div class="row">
      <div>Banned by</div>
      <div id="user-nickname">
        <ImageALevel
          :alevel="options.user.alevel.toString()"
        />
        <span
          :style="{ color: options.user.color }"
          @click="nicknameClick"
        >{{ options.user.nickname }}</span>
      </div>
    </div>
    <Button
      type="green"
      value="Unban user"
      :height="unban_file_button.height"
      @click="unbanUser"
    />
  </div>
</template>

<script>
    import ImageALevel from '@/components/ImageALevel';
    import Button from '@/components/Button';

    export default {
        name: 'BanListInfo',
        components: {
            ImageALevel,
            Button,
        },
        props: {
            options: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                unban_file_button: {
                    height: 26,
                },
            }
        },

        created() {
            console.log(this.options);
        },

        methods: {
            nicknameClick() {
                this.$helper.openURL((this.$external.url.profiles + encodeURIComponent(this.$helper.decode(this.user_data.nickname))), '_blank');
            },
            unbanUser() {
                this.$store.commit('updateModalParams', {
                    opened: false,
                });
                this.options.callback();
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #details {
        position: relative;
        width: 380px;
        padding: 30px 25px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-content: stretch;
        width: 100%;
        margin-bottom: 12px;
        text-align: left;
    }
    .row > div {
        font-weight: 400;
        font-size: 12px;
        color: #4a4a4a;
        line-height: 18px;
    }
    .row > div:first-child {
        width: 75px;
        min-width: 75px;
        color: #9e9e9e;
    }
    .row > div + div {
        width: auto;
    }
    #user-nickname {
        position: relative;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
    #user-nickname:after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 50px;
        top: 0;
        right: 0;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&0+0,1+50,1+100 */
        background: -moz-linear-gradient(left, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
    }
    #user-nickname > * {
        vertical-align: middle;
    }
    #user-nickname > img {
        margin-right: 5px;
    }
    #user-nickname > span {
        display: inline-block;
        font-weight: 400;
        font-size: 13px;
        color: #4a4a4a;
        cursor: pointer;
    }
    #user-nickname > span:hover {
        text-decoration: underline;
    }
    #details > button {
        margin-top: 18px;
    }
</style>
