<template>
  <img
    :src="alevelSrc"
    :class="{ show: is_visible }"
    alt="alevel-icon"
    @load="setVisible"
  >
</template>

<script>
export default {
  name: 'ImageALevel',
  props: {
    alevel: {
      type: Number,
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: 'cf7',
    },
  },
  data() {
    return {
      is_visible: false,
    }
  },
  computed: {
    alevelSrc() {
      let src = '';

      if (this.alevel > 0) {
        src = process.env.VUE_APP_STATIC_CONTENT_DOMAIN_1 + '/images/alevel/' + this.type + '/' + this.alevel + '.svg'
      }

      return src
    }
  },
  methods: {
    setVisible() {
      this.is_visible = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {
        display: none;
        user-select: none;
        vertical-align: middle;
    }
    img.show {
        display: inline-block;
    }
</style>
